import { HMSPrebuilt } from "@100mslive/roomkit-react"
import { FC } from "react"
interface Props {
  roomCode: string
}
const NewSession: FC<Props> = ({ roomCode }) => {
  return (
    <div style={{ height: "100vh" }}>
      <HMSPrebuilt roomCode={roomCode} />
    </div>
  )
}
export default NewSession